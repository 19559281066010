import React from 'react'
import { useState } from 'react'

import './contact.css'
import '../styles/styles.css'

const Contact = () => {

  return (
    <div className='bg-contact' id='contact'>
        <div className="contact-container shadow-inset-center">
            <div className="title-contact reveal fade-bottom">
                <h1>Contact Us</h1>
            </div>
              <form id='contact-form' method='post' action='/contact.php' className="inputs reveal fade-bottom">
              <div className="inputs-fields fade-left">
              <input name="_name" type="text" placeholder='Name...' id='nume' required/>
              <input name="_mail" type="email" id="email" placeholder='Email..' required/>
              </div>
              <div className="text-area fade-right">
                <textarea name="_message" id="text" cols="20" rows="5" placeholder='Your Message' required></textarea>
              </div>
              <a><button className="send" type='submit' value='submit'>Send</button></a>
              </form>
            
        </div>
    </div>
  )
}

export default Contact