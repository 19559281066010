import React from 'react'
import { useState, useEffect } from 'react'

import './components/styles/styles.css'

import {Navbar, Home, Industry, Solution, Company, Footer, Contact} from './components'

const App = () => {

  useEffect(() => {
    const beforeunloadHandle = () => {
      return true;
    };
  
    window.addEventListener("beforeunload", beforeunloadHandle);
    return () => {
      window.removeEventListener("removeEventListener", beforeunloadHandle);
    };
  }, [])

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");
  
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  
  window.addEventListener("scroll", reveal);

  return (
    <div className='wrapper'>
        <Navbar/>
        <Home/>
        <Industry/>
        <Solution/>
        <Company/>
        <Contact/>
        <Footer/>
      </div>      
  )
}

export default App