import React from 'react'

import '../styles/styles.css'

import tracking from '../../assets/tracking.png'
import safety from '../../assets/safety.png'
import automate from '../../assets/frequency.png'

const Solution = () => {
  return (
    <div className="solutions container" id="solution">
      <div className="title-solution">
        <h1 className="title">Solutions</h1>
        <p>Our solutions are based on state-of-the-art AI models for identifying vehicles, people and faces. These models are trained and tuned for optimal operation in scenarios that involve the transportation, storage and shipping of goods.</p>
      </div>
      <div className="info-solution">
        <div className="flex tracking reveal fade-left">
          <div className="img img-tracking"><img src={tracking} alt=""/></div>
          <div className="description">
            <h3>Tracking</h3>
            <p><span className='blue-span'>&#8226;</span>	License plate recognition and vehicle tracking, vehicle search and vehicle intrusion detection
              <br/><span className='blue-span'>&#8226;</span>	Face recognition, people tracking, people search and area intrusion detection
              <br/><span className='blue-span'>&#8226;</span>	Object tracking and search
              </p>
          </div>
        </div>
        <div className="flex safety reveal fade-bottom">
        <div className="img img-safety"><img src={safety} alt=""/></div>
          <div className="description">
            <h3>Safety compliance</h3>
            <p><span className='blue-span'>&#8226;</span>	Detect employees that enter unauthorised areas
            <br/><span className='blue-span'>&#8226;</span> Detect employees that enter high-risk areas without adequate gear (boots, hard hats, gloves)
            <br/><span className='blue-span'>&#8226;</span> Detect employees that undergo risky behaviour in a high-risk area. E.g. smoking in non-smoking designated areas</p>
          </div>
        </div>

        <div className="flex automate reveal fade-right">
          <div className="img img-automate"><img src={automate} alt=""/></div>
          <div className="description">
            <h3>Automatic time sheets</h3>
            <p><span className='blue-span'>&#8226;</span>	Track and record the time each employee spends in a work-related area
            <br/><span className='blue-span'>&#8226;</span>	Track and record each employee's time in other non-work-related areas of interest, like the cantina, smoking areas or hallways.
            <br/><span className='blue-span'>&#8226;</span>	Accurately construct daily, weekly and monthly reports on the time spent by each employee in your areas of interest.
</p>
          </div>
        </div>

      </div>
      <div className="bg-img-solutions min-width max-width"></div>
    </div>
    
  )
}

export default Solution