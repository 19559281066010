import React from 'react'

import '../styles/styles.css'
import { useEffect, useState } from 'react'

import {AiOutlineMenu, AiOutlineClose} from 'react-icons/ai'

import logo from '../../assets/logo.png'

const Navbar = () => {

  const [toggleMenu, setToggleMenu] = useState(false);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const toggleNav = () =>{
    setToggleMenu(!toggleMenu);
  }

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth)
    }

      window.addEventListener('resize', changeWidth)

      return () => {
        window.removeEventListener('resize', changeWidth)
      }
  }, [])
  return (
      <div className="navbar">
        <div className="nav-links">
            <div className="logo-container show">
              {/* <a href="#home">
            <span><span className="span0">STAR</span><span className="span1">VISION</span> </span></a> */}
            <a href="#home"><img src={logo} alt="" srcset="" /></a>
            </div>
            {(toggleMenu || screenWidth > 958) &&  (
              <div className="links-container">
                  <p><a onClick={toggleNav} href="#home">Home</a></p>
                  <p><a onClick={toggleNav} href="#industry">Industries</a></p>
                  <p><a onClick={toggleNav} href="#solution">Solution</a></p>
                  <p><a onClick={toggleNav} href="#company">Company</a></p>
              </div>
          )}   
            <a href="#contact"><div className="btn-contact">Contact</div></a>   
          </div>
          <button onClick={toggleNav} className="icon">
          <AiOutlineMenu className='bars'/>
            </button>
          
      </div>
  )
}

export default Navbar