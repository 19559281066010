import React from 'react'

import '../styles/styles.css'

import team from '../../assets/management.png'

const Company = () => {
  return (
    <div className="company container" id="company">
      <div className="info-company">
      <h1 className="title reveal fade-bottom">Who we are</h1>
        <div className="title-company description">
          <div className="left-side bg-color-transparent reveal fade-left">
              <p> While a relatively new company, Starvision builds on years of research experience in developing and tuning computer vision algorithms and dealing with the problems of logistics and storage of goods.
            <br/><br/>We’ve spent five years refining our hardware, software, and AI models to realise our vision - a cost-effective, all-terrain computer vision solution for the transportation and storage industries. 
            </p>
            <p>
            Our team includes logistics veterans who perfectly understand the challenges of warehousing and shipping.
            </p>
          </div>
          <div className="right-side bg-color-transparent reveal fade-right">
            <p>
            We are proud to announce that in 2021 we finalised our first round of seed funding, and we are currently testing our solution in several MVP projects.
            </p>
            <p>
            Our head office is in Bucharest, but our multinational team are available for your needs around the clock, 365 days a year.
            </p>
          </div>
        </div>
        <div className="img img-team mt-2 reveal fade-bottom"><img src={team} alt=""/></div>
        <div className="team reveal">
          <div className="right-side bg-color-transparent reveal fade-left">
            <p>Our founding research team comprises four veteran AI developers with PhDs in machine learning and computer vision from prestigious universities such as MIT, Université Grenoble Alpes, The University of Manchester and Polytechnic of Bucharest</p>
          </div>
          <div className="right-side bg-color-transparent reveal fade-bottom">
            <p>Our researchers are supported by a DevOps team with extensive experience in cloud, IOT and compute-at-edge infrastructure. Our products are optimised at the kernel-level and deployed on MicroK8s clusters running on multiple Edge Devices.</p>
            </div>
            <div className="right-side bg-color-transparent reveal fade-right">
            <p>Several members of Starvision's management have over ten years of experience in retail, logistics and storage of perishable goods. They are now looking to leverage their expertise in the logistics and transportation industry combined with state-of-the-art technology to give logistics operators better control over long-haul transportation and storage.</p>
          </div>
        </div>
      </div>
      <div className="bg-img-company min-width max-width"></div>
    </div>
  )
}

export default Company