import React from 'react'

import '../styles/styles.css'

import fb from '../../assets/fb.png'
import linked from '../../assets/linked.png'

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-links">
          <p><a href="#home">Home</a></p>
            <p><a href="#industry">Industries</a></p>
            <p><a href="#solution">Solutions</a></p>
            <p><a href="#company">Team</a></p>
            <p><a href="#contact">Contact Us</a></p>
        </div>
        <div className="social">
        <p>Connect with us</p>
        <div className="images">
          <div className="img img-fb"><a href="https://www.facebook.com/starvisionro"><img src={fb} alt=""/></a></div>
          <div className="img img-linked"><a href="https://www.linkedin.com/company/starvisionro"><img src={linked} alt=""/></a></div>
        </div>
        </div>
        <div className="copyright">  
            <p>Copyright © <span><span className="span0-footer">STAR</span><span className="span1-footer">VISION</span> </span> - Smart solutions</p>
            <p>StarVision and all its subsidiaries operate under the legal name of Starchaser SRL</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer