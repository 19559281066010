import React from 'react'

import '../styles/styles.css'

import logistics from '../../assets/logistics.gif';
import warehouse from '../../assets/warehouse.gif';
import delivery from '../../assets/cargo-ship.gif';
import fuel from '../../assets/fuel-theft.png';
import order from '../../assets/order-tracking.png'

const Industries = () => {
  return (
    <div className="container industry" id="industry">
      <div className="info">
        <div className="title-industry reveal fade-bottom">
          <h1 className="title">Industries</h1>
          <p>Extensive market research shows that, after retail, the logistics and warehousing industries are the second to most benefit from AI solutions</p>
        </div>
        <div className="logistics">
        <div className="img img-logistics reveal fade-left"><img src={logistics} alt=""/></div>
          <div className="left-side bg-color-transparent reveal fade-left">
            <h3>Logistics</h3>
            <div>
              <p>Theft and employee-caused disruptions are a severe threat to transport operators, who must deal with the danger of disappearance and misallocation of valuable resources.</p>
              <br/><p>Unscrupulous employees may engage in ‘skimming’ activities by underfilling fuel tanks or misreporting payload weights. These violations are almost impossible to detect manually.</p>   
              <br/><p>StarVision offers a solution to this problem with automated vehicle number plate recognition and driver recognition at every weigh-in.</p>
              <p> Anomalous clusters of data-mismatch around particular employees or vehicles can quickly be identified and traced to their source. </p>
            </div>
          </div>
          <div className="img img-fuel reveal fade-bottom"><img src={fuel} alt=""/></div>
          <div className="line-height right-side bg-color-transparent reveal fade-right">
              <p>Starvision's intelligent solutions also enable next-generation monitoring of vehicle movements via LPR:<br/> </p>
              <p><br /></p>
              <p className='line-height'>
              <span className='line-height blue-span'>&#8226;</span>	Access control can be granted by setting a vehicle's expected arrival and departure times through their respective license plates.
              <br/><span className='blue-span'>&#8226;</span>	Certain license plates can be entered on an "whitelist" for automatic entrance and exit.
              <br/><span className='blue-span'>&#8226;</span>	Prevent automated access for unauthorised vehicles.
              <br/><span className='blue-span'>&#8226;</span>	Reliably track and count vehicles on your premises.
              </p>
            </div>
        </div>
        <div className="warehouse">
          <div className="img img-warehouse reveal fade-left"><img src={warehouse} alt=""/></div>
          <div className="left-side bg-color-transparent reveal fade-left">
            <h3>Warehousing</h3>
            <p>Tracking down mishandled packages and goods is time-consuming and adds overhead to operational costs. Moreover, timely resolution of such incidents is crucial for compliance and high customer satisfaction. </p>
            <p>Starvision's intelligent object search lets warehouse operators find a person or an object of interest among many other entities in minutes. A person's photo, an object size, colour, zone entering, line crossing, and other parameters can be used as search criteria.</p>
          </div>
          <div className="img img-fuel reveal fade-bottom"><img src={order} alt=""/></div>
          <div className="line-height right-side bg-color-transparent reveal fade-right">
              <p>Starvision's state of the art object tracking and identification drastically reduces the time and effort for resolving most mishandling incidents:</p> 
              <p><br /></p>
              <p><span className='line-height blue-span'>&#8226;</span>	Monitor the number of staff entering and exiting buildings. <br/>
              <span className='line-height blue-span'>&#8226;</span>	Receive real-time alerts for suspicious activity using our advanced analytics.<br/>
              <span className='line-height blue-span'>&#8226;</span>	Reduction of time wasted on investigating and handling claims.
                </p>
            </div>
        </div>
        <div className="shipping">
          <div className="img img-shipping reveal fade-left"><img src={delivery} alt=""/></div>
          <div className="left-side bg-color-transparent reveal fade-bottom">
            <h3>Shipping</h3>
            <p>Monitoring employee activity on freight cargo ships has long been a challenge for the shipping industry. Poor internet connection, extreme operating conditions, low power requirements, and the need to store large volumes of data for several months means that bringing the benefits of video analytics to this industry is particularly challenging.</p>
            <p>Thankfully, Starvision has managed to combine the hardware capabilities of the latest edge devices with state-of-the-art AI models to make employee activity tracking for long-haul cargo ship operators a reality.</p>           
          </div>
          <div className="line-height right-side bg-color-transparent reveal fade-right">
              <p>Using Nvidia Jetson AGX technology, Starvision can deploy on any cargo ship a cloud-like micro-cluster of durable, energy-efficient and fault-tolerant devices. This micro-cluster can perform video analytics for months on the ship's security cameras without an internet connection, physical maintenance or outside intervention. Once the internet connection is re-established, the data is uploaded to the client's cloud servers, automatically generating employee activity reports.</p>
              <p><br /></p>
              <p>Starvision's AI micro clusters enable shipping operators to reliability monitor employee activities while they are at sea:</p>
              <p >
              <span className='blue-span'>&#8226;</span>	Record employee activity when they are away from the shore<br/>
              <span className='blue-span'>&#8226;</span>	Record breaches of security and conduct<br/>
              <span className='blue-span'>&#8226;</span>	Generate monthly reports on employee duty time
              </p> 
            </div>
        </div>
      </div>
      <div className="bg-img-industry min-width max-width"></div>
    </div>
  )
}

export default Industries