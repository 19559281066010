import React from 'react'
import '../styles/styles.css'

import img from '../../assets/AI.png'

const Home = () => {
  return (
    <main className="container" id="home">
        <div className="content">
          <div className="title-container">
            <h1 className="title fade-in-left">Starvision <br/> smart solutions</h1>
            <div className="card-home bg-color-transparent">
            <p className='fade-in-left'>We are transforming the logistics industry through the power of video analytics.</p>
            <p><br /></p>
            <p>StarVision provides video analytics solutions with multiple benefits for long-haul logistics and warehousing industries through neural networks powered by AI and deep learning algorithms:<br/>
            <br/><span className='blue-span'>&#8226;</span> best-in-class solutions for vehicle tracking and license plate recognition<br/>
            <span className='blue-span'>&#8226;</span> reliable identification and tracking of employees and other persons of interest<br/>
            <span className='blue-span'>&#8226;</span> real-time evaluation of safety protocols compliance.
            </p>
            </div>

            <div>
            <a href="#industry"><button className="btn-read jello-horizontal">
        <svg width="180px" height="60px" viewBox="0 0 180 60" className="border">
          <polyline points="179,1 179,59 1,59 1,1 179,1" className="bg-line" />
          <polyline points="179,1 179,59 1,59 1,1 179,1" className="hl-line" />
        </svg>
        <span>Read More</span>
      </button></a>
            </div>
            </div>
        <div className="main-img slide-in-right"><img src={img} alt=""/></div>
        </div>
        <div className="bg-img min-width max-width"></div>
      </main>
  )
}

export default Home